// bootstrap のツールボール＆ポップオーバー初期化処理
window.bootstrap = require('bootstrap');
document.addEventListener('turbolinks:load', () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) { // eslint-disable-line
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) { // eslint-disable-line
    return new bootstrap.Popover(popoverTriggerEl);
  });
});
