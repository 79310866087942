/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);  // eslint-disable-line

// layouts/application.html.erb ヘッダーに埋め込まれるため、
// すべての画面遷移毎にリセットされて読み込まれる ==> リロードかけると、JSはリセットされて読み込まれる。

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import './utilities/validation';
import './utilities/lightbox';
import './utilities/bootstrap';

import 'StyleSheets/application';
import 'StyleSheets/utilities/lightbox';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// トーストの初期化
document.addEventListener('turbolinks:load', function () {
  var toastElList = [].slice.call(document.querySelectorAll('.toast'));
  toastElList.map(function (toastEl) {
    return new window.bootstrap.Toast(toastEl).show(); // No need for options; use the default options
  });
});
