// バリデーション
// 制約検証API

// 参考：https://developer.mozilla.org/ja/docs/Learn/HTML/Forms/Data_form_validation
// 参考：https://cccabinet.jpn.org/bootstrap4/components/forms?

// 無効なフィールドがある場合にフォーム送信を無効にするスターターJavaScriptの例
(function () {
  'use strict';

  window.addEventListener(
    'turbolinks:load',
    function () {
      // カスタムブートストラップ検証スタイルを適用するすべてのフォームを取得
      var forms = document.getElementsByClassName('needs-validation');
      // ループして帰順を防ぐ
      Array.prototype.filter.call(forms, function (form) {
        form.addEventListener(
          'submit',
          function (event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();

              form.classList.add('was-validated');
              // 上までスクロール
              var errorPos = ($('.was-validated').offset() && $('.was-validated').offset().top) - 100 || 0;
              $('html, body').animate({ scrollTop: errorPos }, 500, 'swing');
            }
          },
          false
        );
      });
    },
    false
  );
})();
